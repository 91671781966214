<template>
    <promo-landing-page
        image-path-in-assets="images/pages/promo/starbucksLogo.jpg"
        image-width="119"
        title="Get A Free Coffee Every Week"
        message="Use your Aven card at Starbucks and get a free coffee – up to $10 in statement credit – every week for this&nbsp;month!"
        :cta-text="$t('global.cta.gotIt')"
        :on-cta-click="returnToRoot"
    />
</template>

<script>
    import PromoLandingPage from '@/views/promo/PromoLandingPage'
    import { navigation } from '@/mixins/navigation'

    export default {
        name: 'PromoStarbucksFreeCoffee',
        components: {
            'promo-landing-page': PromoLandingPage,
        },
        mixins: [navigation],
    }
</script>
